import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import axios from '../../services/auth-header.js';
import {
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useEffect } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import { useAppContext } from '../../context/AppContext';
import { createPasskey, verifyPasskey } from '../../services/bio-auth';

const LoginForm = ({ Copyright, setIsLogin, setForgotPassword }) => {
	const { setUser } = useAppContext();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');

	const [rememberMe, setRememberMe] = useState(false);
	const [useBiometricAuthentication, setUseBiometricAuthentication] =
		useState(false);
	const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
	const [showPassword, setShowPassword] = useState(false);

	const handleLoginPasskey = async () => {
		try {
			const { user } = await verifyPasskey();
			if (user) {
				setUser({
					admin: user.admin,
					userId: user.userId,
					activated: user.activated,
				});
			}
		} catch (error) {
			setError('Unable to authenticate. Please try again.');
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post('auth/login', {
				email,
				password,
				rememberMe,
			});
			if (response.data) {
				const user = response.data.user;
				if (useBiometricAuthentication) {
					await createPasskey(user);
				} else {
					localStorage.removeItem('preferBiometricAuthentication');
				}
				setUser({
					admin: user.admin,
					userId: user.userId,
					activated: user.activated,
				});
			}
		} catch (error) {
			setError('Incorrect email or password. Please try again.');
			console.error(error);
		}
	};
	useEffect(() => {
		if (isStandalone) {
			const useBioAuth = localStorage.getItem('preferBiometricAuthentication');
			if (useBioAuth) {
				setUseBiometricAuthentication(true);
				handleLoginPasskey();
			}
		}
	}, []);

	return (
		<Box
			sx={{
				my: 8,
				mx: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h5'>
				Login
			</Typography>
			<Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
				<TextField
					margin='normal'
					required
					fullWidth
					id='email'
					label='Email'
					name='email'
					autoComplete='email'
					type='email'
					inputProps={{ maxLength: '100' }}
					autoFocus
					value={email}
					onChange={(e) => {
						if (error !== '') {
							setError('');
						}
						setEmail(e.target.value);
					}}
				/>
				<FormControl required fullWidth>
					<InputLabel htmlFor='password'>Password</InputLabel>
					<OutlinedInput
						id='outlined-adornment-password'
						type={showPassword ? 'text' : 'password'}
						onChange={(e) => {
							if (error !== '') {
								setError('');
							}
							setPassword(e.target.value);
						}}
						autoComplete='password'
						name='password'
						value={password}
						inputProps={{ maxLength: '22' }}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(e) => e.preventDefault()}
									edge='end'>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
					/>
					{!!error && <FormHelperText error>{error}</FormHelperText>}
				</FormControl>
				{getCookieConsentValue() === 'true' && (
					<FormControlLabel
						sx={{ mt: 1 }}
						control={
							<Checkbox
								value={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
								color='primary'
							/>
						}
						label='Remember me'
					/>
				)}
				{isStandalone && (
					<FormControlLabel
						sx={{ width: '100%' }}
						control={
							<Checkbox
								value={useBiometricAuthentication}
								onChange={(e) =>
									setUseBiometricAuthentication(e.target.checked)
								}
								color='primary'
							/>
						}
						label='Use Biometric Authentication'
					/>
				)}
				<Button
					type='submit'
					fullWidth
					variant='contained'
					sx={{ mt: 3, mb: 2 }}>
					Login
				</Button>
				<Grid container>
					<Grid xs={12} item>
						<Link
							href='#'
							onClick={() => setForgotPassword(true)}
							variant='body2'>
							{'Forgot Password?'}
						</Link>
					</Grid>
					<Grid xs={12} item>
						<Link href='#' onClick={() => setIsLogin(false)} variant='body2'>
							{"Don't have an account? Register"}
						</Link>
					</Grid>
				</Grid>
				<Copyright sx={{ mt: 5 }} />
			</Box>
		</Box>
	);
};
export default LoginForm;
