import { useState } from 'react';
import {
	Avatar,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
} from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from '../../services/auth-header.js';
import { useAppContext } from '../../context/AppContext';
const RegisterForm = ({ Copyright, setIsLogin }) => {
	const { setUser } = useAppContext();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [gender, setGender] = useState('P');
	const [zipCode, setZipCode] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [error, setError] = useState('');

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await axios.post('auth/register', {
				email,
				password,
				gender,
				zipCode,
				admin: false,
				activated: true,
			});
			if (response.data) {
				const user = response.data.user;
				setUser({
					admin: user.admin,
					userId: user.userId,
					activated: user.activated,
				});
			}
		} catch (error) {
			console.error(error);
			if (error?.response?.data?.errors) {
				if (
					error?.response?.data?.errors[0]?.message === 'EMAIL must be unique'
				) {
					setError(
						'Sorry, an account with that email already exists. Please try again.'
					);
				}
			}
		}
	};
	return (
		<Box
			sx={{
				my: 8,
				mx: 4,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component='h1' variant='h5'>
				Register
			</Typography>
			<Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='email'>Email</InputLabel>
					<OutlinedInput
						id='email'
						type={'email'}
						onChange={(e) => {
							if (error !== '') {
								setError('');
							}
							setEmail(e.target.value);
						}}
						autoFocus
						autoComplete='email'
						name='email'
						value={email}
						required
						inputProps={{ maxLength: '100' }}
						label='Email'
					/>
					{!!error && <FormHelperText error>{error}</FormHelperText>}
				</FormControl>
				<FormControl margin='normal' required fullWidth>
					<InputLabel htmlFor='password'>Password</InputLabel>
					<OutlinedInput
						id='outlined-adornment-password'
						type={showPassword ? 'text' : 'password'}
						onChange={(e) => setPassword(e.target.value)}
						autoComplete='password'
						name='password'
						value={password}
						required
						inputProps={{ maxLength: '22' }}
						endAdornment={
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={() => setShowPassword(!showPassword)}
									onMouseDown={(e) => e.preventDefault()}
									edge='end'>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						label='Password'
					/>
				</FormControl>
				<TextField
					margin='normal'
					required
					fullWidth
					value={gender}
					name='gender'
					select
					label='Gender'
					onChange={(e) => setGender(e.target.value)}
					id='gender'>
					<MenuItem key={'M'} value='M'>
						Male
					</MenuItem>
					<MenuItem key='F' value='F'>
						Female
					</MenuItem>
					<MenuItem key='O' value='O'>
						Other
					</MenuItem>
					<MenuItem key='P' value={'P'}>
						Prefer not to say
					</MenuItem>
				</TextField>
				<TextField
					margin='normal'
					required
					fullWidth
					value={zipCode}
					name='zipCode'
					label='Zip Code'
					onChange={(e) => setZipCode(e.target.value)}
					inputProps={{ maxLength: 5, minLength: 5 }}
					id='zipCode'
					autoComplete='postal-code'
				/>
				<Button
					type='submit'
					fullWidth
					variant='contained'
					sx={{ mt: 3, mb: 2 }}>
					Register
				</Button>
				<Grid container>
					<Grid item>
						<Link href='#' onClick={() => setIsLogin(true)} variant='body2'>
							{'Already have an account? Login'}
						</Link>
					</Grid>
				</Grid>
				<Copyright sx={{ mt: 5 }} />
			</Box>
		</Box>
	);
};

export default RegisterForm;
