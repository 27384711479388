import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import axios from './services/auth-header.js';
//The firebase API key does not need to be hidden in .env, can check docs for further explanation if confused by this
const firebaseConfig = {
	apiKey: 'AIzaSyB_KrQw5fW7_KTZr79GC_oWKtf6ffr1DPs',
	authDomain: 'secret-diner.firebaseapp.com',
	projectId: 'secret-diner',
	storageBucket: 'secret-diner.appspot.com',
	messagingSenderId: '849821148252',
	appId: '1:849821148252:web:d9e1214cee1ce862afb299',
	measurementId: 'G-DNS5HV7HWB',
};
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const requestForToken = async (userId) => {
	try {
		const currentToken = await getToken(messaging, {
			vapidKey: process.env.REACT_APP_VAPID_KEY,
		});
		if (currentToken) {
			console.log('current token for client: ', currentToken);
			if (
				localStorage.getItem('fcmToken') &&
				currentToken !== localStorage.getItem('fcmToken')
			) {
				localStorage.setItem('fcmToken', currentToken);
			} else if (!localStorage.getItem('fcmToken')) {
				localStorage.setItem('fcmToken', currentToken);
			}
			const response = axios.post('notification/save', {
				userId: userId,
				token: currentToken,
			});
			if (response?.data) {
				console.log(response.data);
			}
		} else {
			console.log(
				'No registration token available. Request permission to generate one.'
			);
		}
	} catch (err) {
		console.log('An error occurred while retrieving token. ', err);
	}
};
export const onMessageListener = () =>
	new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			console.log('here', payload);
			resolve(payload);
		});
	});
