import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import TimerIcon from '@mui/icons-material/Timer';
import Toolbar from '@mui/material/Toolbar';
import PollIcon from '@mui/icons-material/Poll';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../../services/auth-header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { useAppContext } from '../../context/AppContext';
import { Restaurant } from '@mui/icons-material';
import ListIcon from '@mui/icons-material/List';
import { useEffect, useState } from 'react';
import axios from 'axios';

function Sidebar({ drawerWidth, mobileOpen, setMobileOpen, setIsClosing }) {
	const { user, setUser } = useAppContext();
	const [selectedTab, setSelectedTab] = useState();
	const location = useLocation();

	const navigate = useNavigate();

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const doLogout = async () => {
		navigate('/');
		logout();
	};

	const getActiveSurvey = async () => {
		try {
			const result = await axios.get(`survey/response/active/${user.userId}`);
			const surveyResponse = result.data[0];
			if (surveyResponse) {
				setUser({ admin: user.admin, userId: user.userId, activeSurvey: true });
			} else {
				console.log('No active survey');
			}
		} catch (error) {
			console.error('ERROR FETCHING SURVEY:\n' + error);
		}
	};

	useEffect(() => {
		const pathname = location.pathname;
		if (
			pathname === '/admin/survey/templates' ||
			/^\/admin\/survey\/\d+$/.test(pathname)
		) {
			setSelectedTab('Templates');
		} else if (pathname === '/admin/survey/manage') {
			setSelectedTab('Manage');
		} else if (
			pathname === '/admin/restaurant/manage' ||
			/^\/admin\/restaurant\/\d+$/.test(pathname)
		) {
			setSelectedTab('Restaurants');
		} else if (
			pathname === '/admin/survey/statistics' ||
			/^\/admin\/survey\/responses\/\d+$/.test(pathname)
		) {
			setSelectedTab('Statistics');
		} else if (pathname === '/survey/invites') {
			setSelectedTab('Invites');
		} else if (pathname === '/survey/respond') {
			setSelectedTab('Respond');
		} else if (pathname === '/survey/create') {
			setSelectedTab('Create');
		} else if (pathname === '/diner/settings') {
			setSelectedTab('Settings');
		} else {
			if (user.admin) {
				setSelectedTab('Manage');
			} else if (user.activeSurvey) {
				setSelectedTab('Respond');
			} else {
				setSelectedTab('Invites');
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		getActiveSurvey();
	}, []);

	const drawer = (
		<div>
			<Toolbar />
			<Divider />
			{!user.admin ? (
				<List>
					{!user.activeSurvey ? (
						<ListItem
							disablePadding
							component={Link}
							to='/survey/invites'
							selected={selectedTab === 'Invites'}
							onClick={() => setSelectedTab('Invites')}>
							<ListItemButton
								sx={{
									backgroundColor:
										selectedTab === 'Invites'
											? 'rgba(0, 0, 0, 0.08)'
											: 'transparent',
								}}>
								<ListItemIcon>
									<MailIcon />
								</ListItemIcon>
								<ListItemText>Survey Invites</ListItemText>
							</ListItemButton>
						</ListItem>
					) : (
						<ListItem
							disablePadding
							component={Link}
							to='/survey/respond'
							selected={selectedTab === 'Respond'}
							onClick={() => setSelectedTab('Respond')}>
							<ListItemButton
								sx={{
									backgroundColor:
										selectedTab === 'Respond'
											? 'rgba(0, 0, 0, 0.08)'
											: 'transparent',
								}}>
								<ListItemIcon>
									<TimerIcon />
								</ListItemIcon>
								<ListItemText>In Progress</ListItemText>
							</ListItemButton>
						</ListItem>
					)}

					<ListItem
						disablePadding
						component={Link}
						to='/diner/settings'
						selected={selectedTab === 'Settings'}
						onClick={() => setSelectedTab('Settings')}>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Settings'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}>
							<ListItemIcon>
								<PersonIcon />
							</ListItemIcon>
							<ListItemText>Account</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			) : (
				<List>
					{/* Disable until Travis gives us a home screen layout */}
					{/* <ListItem
						disablePadding
						component={Link}
						to='/'
						selected={selectedTab === 'Home'}
						onClick={() => setSelectedTab('Home')}
					>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Home'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}
						>
							<ListItemIcon>
								<HomeIcon />
							</ListItemIcon>
							<ListItemText>Home</ListItemText>
						</ListItemButton>
					</ListItem> */}

					<ListItem
						disablePadding
						component={Link}
						to='/admin/survey/manage'
						selected={selectedTab === 'Manage'}
						onClick={() => setSelectedTab('Manage')}>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Manage'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}>
							<ListItemIcon>
								<PollIcon />
							</ListItemIcon>
							<ListItemText>Launch a Survey</ListItemText>
						</ListItemButton>
					</ListItem>

					<ListItem
						disablePadding
						component={Link}
						to='/admin/survey/templates'
						selected={selectedTab === 'Templates'}
						onClick={() => setSelectedTab('Templates')}>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Templates'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}>
							<ListItemIcon>
								<ListIcon />
							</ListItemIcon>
							<ListItemText>Survey Templates</ListItemText>
						</ListItemButton>
					</ListItem>

					<ListItem
						disablePadding
						component={Link}
						to='/admin/survey/statistics'
						selected={selectedTab === 'Statistics'}
						onClick={() => setSelectedTab('Statistics')}>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Statistics'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}>
							<ListItemIcon>
								<StackedLineChartIcon />
							</ListItemIcon>
							<ListItemText>Survey Data</ListItemText>
						</ListItemButton>
					</ListItem>

					<ListItem
						disablePadding
						component={Link}
						to='/admin/restaurant/manage'
						selected={selectedTab === 'Restaurants'}
						onClick={() => setSelectedTab('Restaurants')}>
						<ListItemButton
							sx={{
								backgroundColor:
									selectedTab === 'Restaurants'
										? 'rgba(0, 0, 0, 0.08)'
										: 'transparent',
							}}>
							<ListItemIcon>
								<Restaurant />
							</ListItemIcon>
							<ListItemText>My Restaurants</ListItemText>
						</ListItemButton>
					</ListItem>
				</List>
			)}
			<Divider />
			<ListItem onClick={doLogout} disablePadding>
				<ListItemButton>
					<ListItemIcon>
						<LogoutIcon />
					</ListItemIcon>
					<ListItemText>Logout</ListItemText>
				</ListItemButton>
			</ListItem>
		</div>
	);

	return (
		<Box
			component='nav'
			sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			aria-label='mailbox folders'>
			{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
			<Drawer
				variant='temporary'
				open={mobileOpen}
				onTransitionEnd={handleDrawerTransitionEnd}
				onClose={handleDrawerClose}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}>
				{drawer}
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				open>
				{drawer}
			</Drawer>
		</Box>
	);
}

export default Sidebar;
