import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from './Sidebar';
import {
	IconButton,
	Typography,
	AppBar,
	Box,
	CssBaseline,
	Toolbar,
	Divider,
} from '@mui/material';
import {
	Menu,
	HomeOutlined,
	Description,
	PersonOutline,
	Storefront,
	StackedLineChart,
} from '@mui/icons-material';
import { logout } from '../../services/auth-header';
import InstallPrompt from '../common/InstallPrompt';
import { useWindowSize } from '@uidotdev/usehooks';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useAppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

const Layout = () => {
	const drawerWidth = 240;
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const { user } = useAppContext();
	const size = useWindowSize();
	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};
	const [tab, setTab] = useState(0);
	const navigate = useNavigate();
	const changeTab = (event, newValue) => {
		setTab(newValue);
		switch (newValue) {
			case 0:
				navigate('/');

				break;
			case 1:
				if (user.admin) {
					navigate('/admin/survey/templates');
				} else {
					navigate('/survey/invites');
				}
				break;
			case 2:
				if (user.admin) {
					navigate('/admin/restaurant/manage');
				} else {
					//TODO add rewards route here if not admin
					navigate('/');
				}
				break;
			case 3:
				if (user.admin) {
					//TODO fix later when settings added for manager, for now statistics
					navigate('/admin/survey/statistics');
				} else {
					navigate('/diner/settings');
				}
				break;
		}
	};
	return (
		<Box sx={{ display: 'flex' }} className='w-full h-full bg-white'>
			<CssBaseline />

			<AppBar
				position='fixed'
				sx={{
					// width: { sm: `calc(100% - ${drawerWidth}px)` },
					// ml: { sm: `${drawerWidth}px` },
					backgroundColor: 'white',
				}}
				elevation={0}
			>
				<Toolbar sx={{ backgroundColor: 'white' }}>
					<IconButton
						className='absolute'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<Menu />
					</IconButton>
					<div className='w-full flex justify-center'>
						<Typography
							variant='h5'
							noWrap
							component='div'
							className='text-[#667085] font-sans font-bold'
						>
							foodcrit.
						</Typography>
					</div>
				</Toolbar>
				<Divider />
			</AppBar>
			<Sidebar
				drawerWidth={drawerWidth}
				mobileOpen={mobileOpen}
				setMobileOpen={setMobileOpen}
				setIsClosing={setIsClosing}
			/>
			<Box
				component='main'
				className='h-full min-h-0'
				sx={{
					flexGrow: 1,
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar className='min-h-8' />
				<Outlet />
			</Box>
			<InstallPrompt />
			{size.width <= 430 && (
				<Paper
					sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					className='z-10'
					elevation={3}
				>
					<BottomNavigation showLabels value={tab} onChange={changeTab}>
						<BottomNavigationAction label='Home' icon={<HomeOutlined />} />
						<BottomNavigationAction label='Survey' icon={<Description />} />
						{user.admin ? (
							<BottomNavigationAction
								label='Restaurant'
								icon={<Storefront />}
							/>
						) : (
							<BottomNavigationAction label='Rewards' icon={<Storefront />} />
						)}
						{user.admin ? (
							<BottomNavigationAction
								label='Data'
								icon={<StackedLineChart />}
							/>
						) : (
							<BottomNavigationAction
								label='Account'
								icon={<PersonOutline />}
							/>
						)}
					</BottomNavigation>
				</Paper>
			)}
		</Box>
	);
};

export default Layout;
