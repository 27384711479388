import axios from '../../services/auth-header.js';
import { useEffect, useState } from 'react';
import Spinner from '../common/spinner/spinner.jsx';
import {
	Stack,
	TextField,
	Card,
	CardContent,
	Box,
	Button,
	Select,
	MenuItem,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from '../common/VisuallyHiddenInput';

const bucketUrl = process.env.REACT_APP_S3_BUCKET_URL;

const Restaurant = () => {
	const [loading, setLoading] = useState(true);
	const [restaurant, setRestaurant] = useState(null);
	const [restaurantName, setRestaurantName] = useState('');
	const [logoPath, setLogoPath] = useState(null);
	const [streetAddress, setStreetAddress] = useState('');
	const [city, setCity] = useState('');
	const [stateAbbr, setStateAbbr] = useState('');
	const [zipCode, setZipCode] = useState('');
	const { id } = useParams();
	const navigate = useNavigate();
	const stateAbbreviations = [
		'AL',
		'AK',
		'AZ',
		'AR',
		'CA',
		'CO',
		'CT',
		'DE',
		'FL',
		'GA',
		'HI',
		'ID',
		'IL',
		'IN',
		'IA',
		'KS',
		'KY',
		'LA',
		'ME',
		'MD',
		'MA',
		'MI',
		'MN',
		'MS',
		'MO',
		'MT',
		'NE',
		'NV',
		'NH',
		'NJ',
		'NM',
		'NY',
		'NC',
		'ND',
		'OH',
		'OK',
		'OR',
		'PA',
		'RI',
		'SC',
		'SD',
		'TN',
		'TX',
		'UT',
		'VT',
		'VA',
		'WA',
		'WV',
		'WI',
		'WY',
	];
	const getRestaurant = async () => {
		getManagerRestaurant();
	};
	const [logoUrl, setLogoUrl] = useState(null);
	const getManagerRestaurant = async () => {
		try {
			const response = await axios.get(`restaurant/${id}`);
			setRestaurant(response.data);
			setRestaurantName(response.data.restaurantName);
			setStreetAddress(response.data.streetAddress);
			setCity(response.data.city);
			setStateAbbr(response.data.stateAbbreviation);
			setZipCode(response.data.zipCode);
			setLogoPath(response.data.logoPath);
			setLogoUrl(
				response.data.logoPath
					? process.env.REACT_APP_S3_BUCKET_URL + response.data.logoPath
					: null
			);
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getRestaurant();
	}, []);

	const handleSubmitButtonClick = async () => {
		try {
			const response = await axios.post(`restaurant/update`, {
				restaurantName: restaurantName,
				streetAddress: streetAddress,
				city: city,
				stateAbbreviation: stateAbbr,
				zipCode: zipCode,
				managerId: restaurant.managerId,
				restaurantId: restaurant.restaurantId,
				logoPath: logoPath,
			});
			if (response) {
				navigate(`/admin/restaurant/manage`);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const imageUploaded = async (e) => {
		console.log('Bucket URL: ' + bucketUrl);
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('image', file);
		try {
			const response = await axios.post('image/create', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});

			const newLogoPath = response.data.imagePath;

			setLogoPath(newLogoPath);
			setLogoUrl(bucketUrl + newLogoPath);

			console.log('Image path: ' + newLogoPath);
		} catch (err) {
			console.error(err);
		}
	};

	return loading || !restaurant ? (
		<Spinner />
	) : !restaurant ? (
		<div>You don't have any restaurants!</div>
	) : (
		<Stack
			alignItems={'center'}
			spacing={2}
			className='max-h-[100vh] overflow-y-scroll py-4 pb-40'
		>
			<Card
				variant='outlined'
				className='w-full overflow-visible'
				style={{ maxWidth: '32rem' }}
			>
				<CardContent>
					<Box component='form' noValidate sx={{ mt: 1 }}>
						{logoUrl && (
							<img className='max-h-20' src={logoUrl} alt='Restaurant Logo' />
						)}
						<Button
							component='label'
							role={undefined}
							variant='contained'
							tabIndex={-1}
							startIcon={<CloudUploadIcon />}
							className='mt-2'
						>
							Upload Logo
							<VisuallyHiddenInput
								type='file'
								accept='image/*'
								onChange={imageUploaded}
							/>
						</Button>
						<TextField
							label='Restaurant Name'
							name='title'
							value={restaurantName}
							onChange={(e) => setRestaurantName(e.target.value)}
							fullWidth
							margin='normal'
						/>
						<TextField
							label='Street Address'
							name='street'
							value={streetAddress}
							onChange={(e) => setStreetAddress(e.target.value)}
							fullWidth
							margin='normal'
						/>
						<TextField
							label='City'
							name='city'
							value={city}
							onChange={(e) => setCity(e.target.value)}
							fullWidth
							margin='normal'
						/>{' '}
						<Select
							id='stateSelect'
							name='state'
							label='State'
							fullWidth
							sx={{ height: '60px' }}
							variant='standard'
							value={stateAbbr}
							onChange={(e) => setStateAbbr(e.target.value)}
						>
							{stateAbbreviations.map((stateAbbr, index) => (
								<MenuItem key={index} value={stateAbbr}>
									{stateAbbr}
								</MenuItem>
							))}
						</Select>
						<TextField
							label='Zip Code'
							name='zipCode'
							value={zipCode}
							onChange={(e) => setZipCode(e.target.value)}
							fullWidth
							margin='normal'
						/>
					</Box>
				</CardContent>
			</Card>
			<Button variant='contained' onClick={handleSubmitButtonClick}>
				Submit
			</Button>
		</Stack>
	);
};

export default Restaurant;
